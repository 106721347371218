<template>
	<div>
    <Header></Header>
		<!--<div>-->
		<!--	<img src="../assets/register.svg" width="100%" height="500px">-->
		<!--</div>-->
		<div class="register">
			<form class="register--form" @submit.prevent="onRegister">
				<div class="register--header">
					<h2 class="register--title">Create your account</h2>
				</div>
				<div v-if="!!validations.length" class="validations">
					<ul style="text-align: left;"><li style="list-style-type: disc;" v-for="(validation, index) in validations" :key="index">{{validation}}</li></ul>
				</div>
				<div class="register--item">
					<label class="register--label" for="displayName">Full Name: </label>
					<input class="register--input" type="text" name="displayName" id="displayName" v-model="user.displayName" @blur="onBlur($event)" placeholder="Enter your full name"/>
				</div>
				<div class="register--item">
					<label class="register--label" for="phoneNumber">Phone: </label>
					<input class="register--input" type="tel" name="phoneNumber" id="phoneNumber" v-model="user.phoneNumber" @blur="onBlur($event)" placeholder="Enter your phone number"/>
				</div>
				<div class="register--item">
					<label class="register--label" for="email">Email: </label>
					<input class="register--input" type="email" name="email" id="email" v-model="user.email" @blur="onBlur($event)" placeholder="Enter your email"/>
				</div>
				<div class="register--item">
					<label class="register--label" for="password">Password: </label>
					<input class="register--input" type="password" name="password" id="password" v-model="user.password" @blur="onBlur($event)" placeholder="Enter your password"/>
				</div>
				<div class="register--item">
					<label class="register--label" for="confirm_password">Confirm Password: </label>
					<input class="register--input" type="password" name="confirm_password" id="confirm_password" v-model="user.confirm_password" @blur="onBlur($event)" placeholder="Re-enter your password"/>
				</div>
				<div class="register--agreement">
					<label class="" for="agreement">
						<input id="agreement" class="" type="checkbox" v-model="user.agreement" aria-label="checkbox" aria-disabled="false" aria-checked="false" aria-labelledby="agreement checkbox" />
						<span class="agreement--content">
							I accept the&nbsp;<a class="auth--link" target="_blank" rel="noopener noreferrer" href="/privacy">Privacy Policy</a>&nbsp;and the&nbsp;
							<a class="auth--link" target="_blank" rel="noopener noreferrer" href="/terms">Terms of Service</a>
						</span>
					</label>
				</div>
				<div class="register--item">
					<button class="register--button" :class="{isValid: isValid}" :disabled="!isValid" type="submit">Register</button>
				</div>
				<div class="register--option">
					<span class="">Have an account?</span>
					<router-link class="auth--link" to="/login">&nbsp;Log in now</router-link>
				</div>
			</form>
		</div>
		<Main></Main>
		<Footer></Footer> 
	</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/partials/Header.vue';
import Footer from '@/components/partials/Footer.vue';
import Main from '@/components/partials/Main.vue';
import { mapActions } from 'vuex';
export default {
	name: 'Register',
	components: {
		Header,
		Footer,
		Main,
	},
	data() {
		return {
			user: {
				displayName: '',
				phoneNumber: '',
				email: '',
				password: '',
				confirm_password: '',
				agreement: false,
			},
			validations: [],
		};
	},
	computed: {
		isValid() {
			return this.user.email !== '' && this.user.password !== '' && this.user.displayName !== '' && this.user.phoneNumber !== '' && this.user.confirm_password !== '' && this.user.agreement !== false;
		},
	},
	methods: {
    ...mapActions(['register', 'setMessage']),
    onBlur(event) {
      event.target.style.borderColor = event.target.value? 'rgba(229,231,235, 1)' : 'rgba(255, 0, 0, 1)';
    },
		validate() {
			// Empty Errors container
			this.validations = []; 
			// Check If Name Is Empty
			if (!this.user.displayName) {
				this.validations.push("Full name is required!");
			}
			// Check If Email Is Empty
			if (!this.user.email) {
				this.validations.push("Email is required!");
			}
			// Check If Phone Is Empty
			if (!this.user.phoneNumber) {
				this.validations.push("Phone number is required!");
			}
			// Check If Password Is Empty
			if (!this.user.password) {
				this.validations.push("Password is required!");
			}
			if(this.user.password && this.user.password != this.user.confirm_password) {
				this.validations.push("Password mis-match, enter matching passwords to continue!");
			}
			// Clear error messages on the page
			setTimeout(() => this.validations = [], 5000);
			// If No Errors Return True
			if (this.validations.length) {
				return false;
			}
			return true;
		},
		async onRegister() {
			if (!this.validate()) return;
			try {
				await this.register(this.user);
				// if (!user) return this.setMessage({text: `Unable to complete request!`, status: false});
				this.$router.push({ name: 'Login', params: this.user })
			} catch (error) {
				this.setMessage({text: error.message, status: false});
			}
		},
	},
};
</script>

<style scoped>
/* register */
.register {
	padding: 1rem;
	width: 100%;
	/*background-image: url('../assets/register.svg');*/
	/*background-position: center;*/
	/*background-size: contain;*/
	/*background-repeat: no-repeat;*/
	/*transition: all 500ms linear;*/
}
.register--form {
	width: 100%;
	margin: 50px auto;
}
.register--title {
	text-align: center;
	margin: 2rem 0;
}
.validations {
	display: flex;
	justify-content: center;
	color: #ff0000;
	padding: .5rem;
	border-radius: 5px;
}
.register--item {
	display: flex;
	flex-direction: column;
	border: none;
	margin: 0px 0px 20px;
	padding: 0px;
}
.register--label {
	font-size: 14px;
	color: rgb(61, 79, 88);
	position: relative;
	height: 16px;
	/*width: 36px;*/
  text-align: left;
	font-weight: bold;
	line-height: 16px;
	letter-spacing: 0.02em;
}
.register--input {
	background-color: rgb(255, 255, 255);
	border: 1px solid rgba(229,231,235, 1);
	border-radius: 4px;
	height: 52px;
	width: 100%;
	font-size: 16px;
	line-height: 24px;
	margin-top: 5px;
	padding-left: 12px;
	padding-right: 12px;
	transition: border-color 150ms ease-in-out 0s;
}
.register--button {
	position: relative;
	background-color: transparent;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	-webkit-box-align: stretch;
	height: 45px;
	width: 100%;
	font-size: 18px;
	margin-top: 48px;
	margin-bottom: 5px;
	border-radius: 4px;
	text-decoration: none;
	cursor: not-allowed;
	z-index: 0;
	/* appearance: none; */
	transition: all 150ms ease-in-out 0s;
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}
.register--button.isValid {
	cursor: pointer;
	background-color: rgba(209,213,219, 1);
}
.register--button.isValid:hover {
  opacity: .5;
}
.register--agreement {
	width: auto;
}
.register--agreement label {
	position: relative;
	display: flex;
	align-items: center;
	-webkit-box-pack: start;
	justify-content: flex-start;
	cursor: pointer;
	margin-top: 8px;
}
.register--agreement input {
	margin: 0px;
	padding: 0;
	height: 1rem;
	width: 1rem;
	cursor: pointer;
	/* position: absolute; */
	/* left: 0px; */
	/* top: 0px; */
	/* pointer-events: none; */
	/* opacity: 0; */
}
.agreement--content {
	position: relative;
	/*color: rgb(70, 76, 79);*/
	margin-left: 3px;
	top: 2px;
	font-size: 14px;
	line-height: 1.3em;
}
.register--option {
	margin: 15px;
	color: rgb(33, 49, 60);
	font-size: 15px;
	display: inline;
	text-align: start;
	display: block;
	text-align: center;
}

.login--body {
	text-align: left;
}
.login--list {
	list-style-type: disc;
}
.auth--link {
	text-decoration: none;
	color: rgb(0, 124, 173);
}
/* mini */
@media only screen and (min-width: 481px) {
.register {
	background-image: url('../assets/register.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	transition: all 500ms linear;
}
.register--form {
	width: 410px;
	margin: 0 auto;
}
}
/* max */
@media only screen and (min-width: 964px) {

}
</style>